<template>
  <div class="row">
    <div class="col-md-12">

      <div
        class="card card-custom gutter-b example example-compact"
        v-if="filter"
      >
        <div class="card-header">
          <div class="row my-2 flex-wrap align-items-center">
            <div class="col-2">
              <!-- <div class="input-icon"> -->
              <input
                type="text"
                v-model="id"
                v-debounce:400ms="myFn"
                class="form-control"
                placeholder="Id"
              />
              <!-- </div> -->
            </div>
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="
                    sections.filters.contract__client__full_name__icontains
                  "
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Mijoz nomi"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.contract__client__inn__icontains"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Inn"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-3">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="
                    sections.filters.contract__contract_number__icontains
                  "
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="BTS shartnoma raqami"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-3">
              <div class="d-flex align-items-center">
                <select
                  v-model="sections.filters.deliver"
                  @change="myFn"
                  class="form-control"
                >
                  <option value="">Tashuvchi</option>
                  <option value="">Barcha</option>
                  <option value="BTS">BTS kompaniya</option>
                  <option value="POS">Postavshik</option>
                </select>
              </div>
            </div>

            <div class="col-3">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="sections.filters.start_date_after"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    :value="sections.filters.start_date_after | formatDate"
                    type="text"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @change="myFn"
                    class="form-control"
                    placeholder="Sanadan"
                  />
                </template>
                <!-- :min="sections.filters.start_date_before || null" -->
                <v-date-picker
                  v-model="sections.filters.start_date_after"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      () => {
                        $refs.dialog.save(sections.filters.start_date_after)
                        myFn()
                      }
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
            <div class="col-3">
              <v-dialog
                ref="dialog2"
                v-model="modal2"
                :return-value.sync="sections.filters.start_date_before"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    :value="sections.filters.start_date_before | formatDate"
                    type="text"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @input="myFn"
                    class="form-control"
                    placeholder="Sanagacha"
                  />
                </template>
                <!-- :max="sections.filters.start_date_after || null" -->
                <v-date-picker
                  v-model="sections.filters.start_date_before"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      () => {
                        $refs.dialog2.save(sections.filters.start_date_before)
                        myFn()
                      }
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
            <div class="col-3">
              <div class="d-flex align-items-center">
                <select
                  v-model="sections.filters.zayavka_status"
                  @change="myFn"
                  class="form-control"
                >
                  <option value="">Status</option>
                  <option value="">Barcha</option>
                  <option value="OTB">Buyurtmachida</option>
                  <option value="OTW">Yo'lda</option>
                  <option value="DEL">Yetkazilgan</option>
                </select>
              </div>
            </div>
            <div class="col-3">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.comment__icontains"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Izoh"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>

            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.price_min"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="BTS narx(dan)"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.price_max"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="BTS narx(gacha)"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-3">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.price_with_nds_min"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="BTS Narxi Nds bilan (dan)"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-3">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.price_with_nds_max"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="BTS Narx Nds bilan (gacha)"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.truck__reg_number__icontains"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Bts mashina raqami"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>

            <div class="col-3">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.postavshik__full_name__icontains"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Postavshik nomi"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-3">
              <!-- <div class="input-icon"> -->
              <input
                type="text"
                v-model="
                  sections.filters
                    .postavshik_contract__contract_number__icontains
                "
                v-debounce:400ms="myFn"
                class="form-control"
                placeholder="Postavshik shartnoma raqami"
              />
              <!-- </div> -->
            </div>
            <div class="col-3">
              <!-- <div class="input-icon"> -->
              <input
                type="text"
                v-model="sections.filters.postavshik_driver__icontains"
                v-debounce:400ms="myFn"
                class="form-control"
                placeholder="Postavshik haydovchisi"
              />
            </div>
            <div class="col-3">
              <!-- <div class="input-icon"> -->
              <input
                type="text"
                v-model="sections.filters.postavshik_truck_number__icontains"
                v-debounce:400ms="myFn"
                class="form-control"
                placeholder="Postavshik mashina raqami"
              />
            </div>

            <div class="col-3">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.postavshik_price_min"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Postavshik Narx (dan)"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-3">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.postavshik_price_max"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Postavshik Narx (gacha)"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-3">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.postavshik_price_with_nds_min"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Postavshik Narx Nds bilan(dan)"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-3">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.postavshik_price_with_nds_max"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Postavshik Narx Nds bilan (gacha)"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>

            <div class="col">
              <a @click="reset" class="btn btn-primary font-weight-bolder">
                {{ $t('BREADCRUMBS.CLEAN') }}</a
              >
            </div>
          </div>

          <div class="col-6 pl-0">
            <v-autocomplete
              v-show="false"
              v-model="city_1"
              @change="myFn"
              :items="allClientNames"
              outlined
              item-text="name"
              clearable
              item-value="id"
              dense
              label="Shahar 1"
            ></v-autocomplete>
          </div>
          <div class="col-6 pr-0">
            <v-autocomplete
              v-show="false"
              v-model="city_2"
              @change="myFn"
              :items="allClientNames"
              outlined
              item-text="name"
              clearable
              item-value="id"
              dense
              label="Shahar 2"
            ></v-autocomplete>
          </div>
        </div>
      </div>
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('BREADCRUMBS.ORDERS') }}
              <div></div>
            </h3>
          </div>

          <v-row class="m-0 align-items-center">
            <div class="mr-4">
              <v-menu
                ref="yearMenuRef"
                v-model="menuDate.year"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Hisobot yili"
                    :nudge-right="40"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    clearable
                    :value="
                      filterDate.start_date__year &&
                      `${filterDate.start_date__year || currentYear}-01-01`
                        | yearOnly
                    "
                    @input="onInput(arguments[0], 'start_date__year')"
                  />
                </template>
                <v-date-picker
                  ref="yearPickerRef"
                  :value="`${filterDate.start_date__year || currentYear}-01-01`"
                  locale="ru"
                  type="year"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="2010-01-01"
                  @click:year="save(arguments[0], 'start_date__year')"
                ></v-date-picker>
              </v-menu>
            </div>

            <div class="mr-4">
              <v-menu
                ref="yearMonthRef"
                v-model="menuDate.month"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="ml-2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Oy"
                    :nudge-right="40"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :value="
                      filterDate.start_date__month &&
                      `${filterDate.start_date__year || currentYear}-${
                        filterDate.start_date__month || '01'
                      }-01` | monthOnly
                    "
                    hide-details
                    clearable
                    @input="onInput(arguments[0], 'start_date__month')"
                  />
                </template>
                <v-date-picker
                  ref="monthPickerRef"
                  :value="`${filterDate.start_date__year || currentYear}-${
                    filterDate.start_date__month || '01'
                  }-01`"
                  locale="ru"
                  no-title
                  type="month"
                  min="2010-01-01"
                  @click:month="save(arguments[0], 'start_date__month')"
                />
              </v-menu>
            </div>

            <div class="mr-4">
              <div class="d-flex align-items-center">
                <el-button
                  style="
                    min-width: 5rem;
                    background-color: #ecf5ff;
                    color: #409eff;
                  "
                  @click="onSearchByDate"
                >
                  Izlash
                </el-button>
              </div>
            </div>
          </v-row>

          <div class="card-button d-flex">
            <div class="card-toolbar mr-1">
              <button
                @click="toExcel"
                class="btn btn-success font-weight-bolder mr-2"
                :class="
                  isLoading ? 'spinner spinner-darker-light spinner-right' : ''
                "
              >
                <span class="svg-icon svg-icon-white"
                  ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/General/Search.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >

                {{ $t('BREADCRUMBS.TO_EXCEL') }}
              </button>
              <a
                @click="filter = !filter"
                class="btn btn-warning font-weight-bolder"
                :style="$route.query.name && 'display: none'"
              >
                <span class="svg-icon svg-icon-white"
                  ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/General/Search.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >

                Filter</a
              >
            </div>

            <div class="card-toolbar">
              <router-link
                to="/addZayavka"
                class="btn btn-primary font-weight-bolder"
                :style="$route.query.name && 'display: none'"
              >
                <span class="svg-icon svg-icon-white"
                  ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Communication/Outgoing-box.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M22,17 L22,21 C22,22.1045695 21.1045695,23 20,23 L4,23 C2.8954305,23 2,22.1045695 2,21 L2,17 L6.27924078,17 L6.82339262,18.6324555 C7.09562072,19.4491398 7.8598984,20 8.72075922,20 L15.381966,20 C16.1395101,20 16.8320364,19.5719952 17.1708204,18.8944272 L18.118034,17 L22,17 Z"
                        fill="#000000"
                      />
                      <path
                        d="M2.5625,15 L5.92654389,9.01947752 C6.2807805,8.38972356 6.94714834,8 7.66969497,8 L16.330305,8 C17.0528517,8 17.7192195,8.38972356 18.0734561,9.01947752 L21.4375,15 L18.118034,15 C17.3604899,15 16.6679636,15.4280048 16.3291796,16.1055728 L15.381966,18 L8.72075922,18 L8.17660738,16.3675445 C7.90437928,15.5508602 7.1401016,15 6.27924078,15 L2.5625,15 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        transform="translate(11.959697, 3.661508) rotate(-90.000000) translate(-11.959697, -3.661508) "
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >

                {{ $t('BREADCRUMBS.ORDER') }} +</router-link
              >
            </div>
          </div>
        </div>
        <router-view
          ref="childRef"
          :filter-props="sections.filters"
          @change-page="myFn"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'
import { monthOnly, yearOnly } from '@/utils/filters/dateFormats.js'
import { takeFilledKeyValue } from '@/utils'

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth() + 1

export default {
  data() {
    return {
      currentYear,

      dataToExcel: {},
      modal: false,
      modal2: false,
      filter: false,

      sections: {
        filters: {
          contract__client__full_name__icontains: '',
          contract__client__inn__icontains: '',
          contract__contract_number__icontains: '',
          deliver: '',
          start_date_before: '',
          start_date_after: '',
          zayavka_status: '',
          comment__icontains: '',
          price_min: '',
          price_max: '',
          price_with_nds_min: '',
          price_with_nds_max: '',
          truck__reg_number__icontains: '',
          postavshik__full_name__icontains: '',
          postavshik_contract__contract_number__icontains: '',
          postavshik_driver__icontains: '',
          postavshik_truck_number__icontains: '',
          postavshik_price_min: '',
          postavshik_price_max: '',
          postavshik_price_with_nds_min: '',
          postavshik_price_with_nds_max: ''
        }
      },
      conttype: '',
      id: '',
      ndsWithPrice: '',
      city_1: '',
      city_2: '',

      menuDate: {
        year: false,
        month: false
      },

      filterDate: {
        start_date__year: +this.$route.query.start_date__year || currentYear,
        start_date__month: +this.$route.query.start_date__month || currentMonth
      }
    }
  },
  // created() {
  // this.$store.dispatch('getAllCities')
  // if (this.$store.state.requests.filterData.data !== undefined) {
  //   const data = this.$store.state.requests.filterData.data
  //   if (
  //     this.$store.state.requests.filterData.path.substring(
  //       0,
  //       this.$route.path.lastIndexOf('/')
  //     ) == this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))
  //   ) {
  //     this.filter = true
  //     this.postavshik_price_min = data.postavshik_price_min
  //     this.contract__contract_number__icontains =
  //       data.contract__contract_number__icontains
  //     this.postavshik_price_max = data.postavshik_price_max
  //     this.postavshik_price_with_nds_min = data.postavshik_price_with_nds_min
  //     this.postavshik_price_with_nds_max = data.postavshik_price_with_nds_max
  //     this.postavshik_driver__icontains = data.postavshik_driver__icontains
  //     this.postavshik_contract__contract_number__icontains =
  //       data.postavshik_contract__contract_number__icontains
  //     this.postavshik__full_name__icontains =
  //       data.postavshik__full_name__icontains
  //     this.comment__icontains = data.comment__icontains
  //     this.start_date_after = data.start_date_after
  //     this.start_date_before = data.start_date_before
  //     this.price_min = data.price_min
  //     this.price_max = data.price_max
  //     this.price_with_nds_min = data.price_with_nds_min
  //     this.price_with_nds_max = data.price_with_nds_max
  //     this.ndsWithPrice = data.price_with_nds
  //     this.city_1 = data.from_city
  //     this.city_2 = data.to_city
  //     this.zayavka_status = data.zayavka_status
  //     this.postavshik_truck_number__icontains =
  //       data.postavshik_truck_number__icontains
  //     this.deliver = data.deliver
  //     this.contract__client__inn__icontains =
  //       data.contract__client__inn__icontains
  //     this.contract__client__full_name__icontains =
  //       data.contract__client__full_name__icontains
  //     this.truck__reg_number__icontains = data.truck__reg_number__icontains
  //     this.id = data.id
  //   } else {
  //     this.filter = false
  //     this.$store.commit('setFilterData', {
  //       data: undefined,
  //       path: ''
  //     })
  //   }
  // }

  // this.myFn()
  // },

  filters: {
    monthOnly,
    yearOnly
  },

  computed: {
    allClientNames() {
      // console.log(this.$store.state.requests.Cities)
      return this.$store.state.requests.Cities
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t('BREADCRUMBS.SOLD') },
        { title: this.$t('BREADCRUMBS.ORDERS') }
      ])
    })
  },

  methods: {
    save(dateTimeValue, datePickerPropKey) {
      let t = dateTimeValue

      if (datePickerPropKey == 'start_date__month' && t) {
        t = parseInt(dateTimeValue.slice(5))
      }

      this.filterDate[datePickerPropKey] = t
    },

    onInput(t = null, arg) {
      this.filterDate[arg] = t
    },

    onSearchByDate() {
      this.sections.filters.start_date_before = ''
      this.sections.filters.start_date_after = ''

      // console.log('this.filterDate', this.filterDate)

      console.log();

      const props = takeFilledKeyValue(this.filterDate)
      console.log(props, 'TEST PROPS')

      if(!(Object.keys(props).length > 0)) this.$router.replace( {query: {}})

      this.$router
        .replace({
          name: 'allzayavkaPAge',
          params: { id: 1 },
          query: { ...this.$route.query, ...props, rn: Math.random().toString().slice(2, 6) }
        })
        .catch(() => {
          //todo
        })
    },

    toExcel() {
      this.$store.commit('setIsLoading', true)

      axios({
        method: 'post',
        url: 'zayavkas/excel/',
        responseType: 'blob',
        data: this.dataToExcel
      }).then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Zayavka')
        document.body.appendChild(link)
        link.click()
        this.$store.commit('setIsLoading', false)
      })
    },
    myFn() {
      let data = {}
      Object.keys(this.sections.filters).forEach((key) => {
        if (
          ['start_date_before', 'start_date_after'].includes(key) &&
          this.sections.filters[key]
        ) {
          this.filterDate.start_date__year = ''
          this.filterDate.start_date__month = ''
        }

        if (
          [
            'postavshik_price_min',
            'postavshik_price_max',
            'postavshik_price_with_nds_min',
            'postavshik_price_with_nds_min',
            'postavshik_price_with_nds_max',
            'price_with_nds_min',
            'price_with_nds_max',
            'price_with_nds'
          ].includes(key)
        ) {
          data[key] = parseFloat(this.sections.filters[key])
        } else if (['price_min', 'price_max'].includes(key)) {
          data[key] = parseInt(this.sections.filters[key], 10)
        }

        data[key] = this.sections.filters[key]
      })

      Object.keys(this.filterDate).forEach((key) => {
        data[key] = this.filterDate[key]
      })

      data.from_city = this.city_1
      data.to_city = this.city_2
      data.id = this.id

      data = takeFilledKeyValue(data)

      // console.log('req data', data);

      this.$store.commit('setFilterData', {
        data: data,
        path: this.$route.path
      })

      this.dataToExcel = data

      this.$refs.childRef.getPostData(1, data)
    },
    reset() {
      Object.keys(this.sections.filters).forEach((key) => {
        this.sections.filters[key] = ''
      })

      this.ndsWithPrice = ''
      this.city_1 = ''
      this.city_2 = ''
      this.id = ''

      this.inn = ''
      this.type = ''
      this.conttype = ''

      this.$store.commit('setFilterData', {
        data: undefined,
        path: ''
      })

      let query = {}

      Object.keys(this.filterDate).forEach((key) => {
        query[key] = this.filterDate[key]
      })

      this.$router.push({
        params: { id: 1 },
        query: {
          ...query,
          rn: Math.random().toString().slice(2, 6)
        }
      })
    }
  },

  watch: {
    'menuDate.year': {
      handler(val) {
        if (val)
          setTimeout(() => (this.$refs.yearPickerRef.activePicker = 'YEAR'))
      }
    },
    'menuDate.month': {
      handler(val) {
        if (val)
          setTimeout(() => (this.$refs.monthPickerRef.activePicker = 'MONTH'))
      }
    }
  }
}
</script>
<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
